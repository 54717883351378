import styled from 'styled-components';
import { Text14Light, Text18Light } from '../utils/Text/Text.styles';

export const UploadFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ButtonContainer = styled.div`
  width: ${({ $noMediaUploaded }) => ($noMediaUploaded ? '100%' : '11.5rem')};
  height: ${({ $noMediaUploaded }) => ($noMediaUploaded ? '28.4rem' : '11.5rem')};
  background: ${({ $noMediaUploaded }) =>
    $noMediaUploaded ? 'transparent' : 'linear-gradient(180deg, #552b9d 0%, #3b1e70 100%)'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: relative;
  border-radius: 8px;
`;

export const UploadText = styled(Text18Light)`
  color: #c99aff;
  text-decoration: underline;
`;

export const Text = styled(Text14Light)`
  opacity: 0.56;
  color: #c99aff;
`;
