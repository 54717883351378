import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
  DatePicker,
  ErrorContainer,
  Input,
  OnboardingContainer,
  RadioButton,
  UploadProfilePhotoInput,
} from '../../../components';
import { Content, Form, QuestionnaireSection, SectionTitle, StyledButton } from './UpdateBasicInfoPage.styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { UPLOAD_FILE_CONSTRAINTS } from '../../../utils/constants';
import CropImageModal from '../../../components/modals/CropImageModal/CropImageModal';
import { useDispatch, useSelector } from 'react-redux';
import { notifyError, notifyInfo } from '../../../utils/notify';
import { updateBasicInfo } from '../../../store/slices/influencerRequest/asyncThunks';
import {
  selectInfluencerRequestActionsPending,
  selectQuestionnaire,
} from '../../../store/slices/influencerRequest/slice';
import { Text14Light } from '../../../components/utils/Text/Text.styles';

export const updateBasicInfoFormSchema = yup
  .object({
    name: yup.string().trim().required('Name is required').max(255, 'Max length reached'),
    dateOfBirth: yup
      .date()
      .required('Birth of date is required')
      .max(new Date(new Date().setFullYear(new Date().getFullYear() - 18)), 'Influencer must be 18+'),
    profilePhoto: yup.mixed().required('Profile photo is required'),
  })
  .required();

const questionnaireFormSchema = yup
  .object()
  .shape({
    answers: yup.lazy(value => {
      const newEntries = Object.keys(value).reduce(
        (acc, val) => ({
          ...acc,
          [val]: yup.string().trim().required('Field is required'),
        }),
        {},
      );
      return yup.object().shape(newEntries);
    }),
  })
  .required();

const UpdateBasicInfoPage = () => {
  const cropImageRef = useRef();
  const { updateBasicInfo: updateBasicInfoPending } = useSelector(selectInfluencerRequestActionsPending);
  const questionnaire = useSelector(selectQuestionnaire);
  const [basicInfo, setBasicInfo] = useState(null);
  const [questionnaireStep, setQuestionnaireStep] = useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    getValues,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      dateOfBirth: null,
      profilePhoto: null,
    },
    delayError: 300,
    resolver: yupResolver(updateBasicInfoFormSchema),
  });

  const {
    register: questionnaireRegister,
    handleSubmit: questionnaireHandleSubmit,
    control: questionnaireControl,
    formState: { errors: questionnaireErrors },
  } = useForm({
    defaultValues: {
      answers: {},
    },
    delayError: 300,
    resolver: yupResolver(questionnaireFormSchema),
  });

  const watchProfilePhoto = watch('profilePhoto');

  useEffect(() => {
    if (watchProfilePhoto) {
      if (typeof watchProfilePhoto !== 'string') {
        cropImageRef.current.show();
      }
    }
  }, [watchProfilePhoto]);

  const onSubmit = data => {
    setBasicInfo(data);
    setQuestionnaireStep(true);
  };

  const onSubmitQuestionnaire = async data => {
    const questionnaireData = [];
    Object.entries(data.answers).forEach(([key, value]) => {
      const [sectionIndex, questionIndex] = key.split('-');
      const title = questionnaire[sectionIndex].title;
      const question = questionnaire[sectionIndex].questions[questionIndex];
      questionnaireData.push({
        title,
        question,
        answer: value,
      });
    });

    dispatch(updateBasicInfo({ ...basicInfo, questionnaireData }))
      .unwrap()
      .then(() => {
        notifyInfo('Basic info updated successfully');
      })
      .catch(err => {
        notifyError(err.message);
      });
  };

  return (
    <OnboardingContainer>
      <Content>
        {questionnaireStep ? (
          <Form onSubmit={questionnaireHandleSubmit(onSubmitQuestionnaire)}>
            {questionnaire.map((question, index) => {
              if (question.title === 'Other') {
                return (
                  <QuestionnaireSection key={index}>
                    {question.questions.map((q, i) => {
                      return (
                        <Fragment key={i}>
                          <Text14Light>{q}</Text14Light>
                          <Controller
                            name={`answers[${index}-${i}]`}
                            defaultValue={'AI'}
                            control={questionnaireControl}
                            render={({ field }) => {
                              return (
                                <RadioButton
                                  value={field.value}
                                  onChange={field.onChange}
                                  options={[
                                    { value: 'AI', label: 'AI' },
                                    { value: 'Real Person', label: 'Real Person' },
                                  ]}
                                />
                              );
                            }}
                          />
                          <ErrorContainer errorText={questionnaireErrors.answers?.[`${index}-${i}`]?.message} />
                        </Fragment>
                      );
                    })}
                  </QuestionnaireSection>
                );
              } else {
                return (
                  <QuestionnaireSection key={index}>
                    <SectionTitle>{question.title}</SectionTitle>
                    {question.questions.map((q, i) => {
                      return (
                        <Input
                          key={i}
                          label={q}
                          type="textarea"
                          placeholder="Enter text"
                          {...questionnaireRegister(`answers[${index}-${i}]`)}
                          error={questionnaireErrors.answers?.[`${index}-${i}`]?.message}
                        />
                      );
                    })}
                  </QuestionnaireSection>
                );
              }
            })}
            <StyledButton title="Continue" isLoading={updateBasicInfoPending} />
          </Form>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="profilePhoto"
              control={control}
              render={({ field }) => {
                return (
                  <UploadProfilePhotoInput
                    label="Profile photo"
                    value={field.value}
                    onChange={field.onChange}
                    type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
                    error={errors.profilePhoto?.message}
                  />
                );
              }}
            />
            <Input label="Profile Name" placeholder="Enter name" {...register('name')} error={errors.name?.message} />
            <Controller
              name="dateOfBirth"
              control={control}
              render={({ field }) => {
                return (
                  <DatePicker
                    label="Date of birth"
                    placeholder="Enter date of birth"
                    value={field.value}
                    onChange={field.onChange}
                    error={errors.dateOfBirth?.message}
                  />
                );
              }}
            />
            <StyledButton title="Continue" />
          </Form>
        )}
      </Content>
      <CropImageModal
        ref={cropImageRef}
        onClose={() => {
          setValue('profilePhoto', null);
          cropImageRef.current.hide();
        }}
        onComplete={croppedImage => {
          setValue('profilePhoto', croppedImage);
          cropImageRef.current.hide();
        }}
        originalImage={getValues('profilePhoto')}
      />
    </OnboardingContainer>
  );
};

export default UpdateBasicInfoPage;
