import React, { useEffect, useState } from 'react';
import { Container, Content, Header, HeaderRow, HeaderText, NumOfMediaText, StyledButton } from './UploadPhotos.styles';
import { Text16Bold } from '../utils/Text/Text.styles';
import { MIN_NUM_OF_PHOTOS, UPLOAD_FILE_CONSTRAINTS } from '../../utils/constants';
import { notifyError, notifyInfo } from '../../utils/notify';
import { useDispatch } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';
import UploadMediaButton from '../UploadMediaButton/UploadMediaButton';
import MediaComponent from '../MediaComponent/MediaComponent';
import { updateNumOfUpdatedMedia } from '../../store/slices/user/slice';
import { uploadPhoto } from '../../store/slices/influencerRequest/asyncThunks';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-01.svg';

const UploadPhotos = () => {
  const dispatch = useDispatch();
  const [photos, setPhotos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploadingProgress, setUploadingProgress] = useState([]);
  const [loading, setLoading] = useState(false);

  const myUploadProgress = index => progress => {
    let percentage = Math.floor((progress.loaded * 100) / progress.total);
    setUploadingProgress(prev => {
      const newProgress = [...prev];
      newProgress[index] = percentage > 99 ? 99 : percentage;
      return newProgress;
    });
  };

  useEffect(() => {
    if (uploadingProgress.length && !uploadingProgress.filter(progress => progress !== 100).length) {
      dispatch(
        updateNumOfUpdatedMedia({ numOfMedia: uploadingProgress.length, type: UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO }),
      );
      setLoading(false);
      notifyInfo('Photos uploaded successfully');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadingProgress]);

  const onUploadClick = () => {
    setLoading(true);
    for (const [index, photo] of photos.entries()) {
      dispatch(
        uploadPhoto({
          photo,
          onUploadProgress: myUploadProgress(index),
        }),
      )
        .unwrap()
        .then(() => {
          setUploadingProgress(prev => {
            const newProgress = [...prev];
            newProgress[index] = 100;
            return newProgress;
          });
        })
        .catch(err => {
          setLoading(false);
          notifyError(err.message);
        });
    }
  };

  const onPhotoDelete = (index, fileName) => {
    setPhotos(prev => prev.filter((_, ind) => index !== ind));
    setErrors(prev => prev.filter(error => error.fileName !== fileName));
  };

  return (
    <Container>
      <Header>
        <HeaderRow>
          <Text16Bold>Photos</Text16Bold>
          {photos.length < MIN_NUM_OF_PHOTOS ? (
            <NumOfMediaText>
              {photos.length}/{MIN_NUM_OF_PHOTOS}
            </NumOfMediaText>
          ) : (
            <CheckIcon />
          )}
        </HeaderRow>
        <HeaderText>Upload at least {MIN_NUM_OF_PHOTOS} exclusive pictures. </HeaderText>
      </Header>
      <Content>
        <UploadMediaButton
          numOfUploadedMedia={photos.length}
          disabled={loading}
          type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
          onChange={files => setPhotos([...photos, ...files])}
          setError={setErrors}
        />
        {photos.map((photo, index) => {
          return (
            <MediaComponent
              key={photo.file.name}
              type={UPLOAD_FILE_CONSTRAINTS.TYPE.PHOTO}
              file={photo}
              progress={uploadingProgress[index]}
              onDelete={() => onPhotoDelete(index, photo.file.name)}
              error={errors.find(error => error.fileName === photo.file.name)}
            />
          );
        })}
      </Content>
      <StyledButton
        title="Continue"
        onClick={onUploadClick}
        isLoading={loading}
        disabled={photos.length < MIN_NUM_OF_PHOTOS || !!errors.length}
      />
    </Container>
  );
};

export default UploadPhotos;
