import styled from 'styled-components';
import { Text13Bold, Text14Light } from '../utils/Text/Text.styles';

export const MediaContainer = styled.div`
  width: 11.5rem;
  height: 11.5rem;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  display: flex;

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const CloseWrapper = styled.div`
  width: 2rem;
  height: 2rem;
  border-radius: 7px;
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  background: rgba(75, 38, 140, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

export const ProgressContainer = styled.div`
  width: 8rem;
  height: 8rem;
  align-self: center;
  margin: auto;
`;

export const VoiceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding-inline: 1.6rem;
  width: 100%;
  background-color: #3f2078;

  p {
    text-align: center;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

export const Error = styled.div`
  position: absolute;
  inset: 0;
  background-color: #00000090;
  padding: 2px 2px 4px 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ErrorText = styled(Text13Bold)`
  letter-spacing: -0.42px;
`;

export const ErrorMessageText = styled(Text14Light)`
  letter-spacing: -0.42px;
`;
